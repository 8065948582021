import React from "react";
import PropTypes from "prop-types";

const Totalizer = ({ title, counter }) => (
  <div>
    <p>{title}</p>
    <h2>{counter}</h2>
  </div>
);
Totalizer.propTypes = {
  title: PropTypes.string,
  counter: PropTypes.number
};
Totalizer.defaultProps = {
  title: "",
  counter: 0
};
export { Totalizer };
