import { actionTypes } from '../../../utils';
const { DASHBOARD_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES, SET_STUDENTS, SET_HEADER_TITLE, CLEAR_HEADER_TITLE } =
  DASHBOARD_ACTIONS;
const INITIAL_STATE = {
  universities: [],
  headerTitle: '',
  selectedStartDate: '',
  selectedEndDate: ''
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_UNIVERSITIES:
      return {
        ...state,
        universities: [...action.payload]
      };
    case SET_STUDENTS:
      return {
        ...state,
        students: [...action.payload]
      };
    case SET_HEADER_TITLE:
      return {
        ...state,
        headerTitle: action.payload
      };
    case CLEAR_HEADER_TITLE:
      return {
        ...state,
        headerTitle: ''
      };
    default:
      return state;
  }
};
