import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import PropTypes from 'prop-types';

export default function RestaurentDialogBox({ open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogBoxCls adminDialog"
      >
        <DialogTitle id="alert-dialog-title">Favorite Restaurant</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <textarea placeholder="Please enter campus" className="textField" />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogButtons">
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            className="confirmBtn"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

RestaurentDialogBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
