import React, { useEffect, useState } from 'react';
import logo from '../../resources/images/umii-logo.png';
import { CircularProgress, Button } from '@mui/material';
import { config } from '../../api/endPoints';
import { axiosBasic } from '../../api/axios';
import amplitude from 'amplitude-js';
import { Common } from '../../utils';

const {
  ROOT_URL,
  REST_API: { Account }
} = config;

let params = new URL(document.location).searchParams;
let userId = params.get('userId');
let code = params.get('code');

const ConfirmUserPage = () => {
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userId) {
      axiosBasic({
        method: 'GET',
        url: `User/${userId}/Email`
      }).then((res) => {
        const email = res.data.data;
        setUserEmail(email);
        setLoading(false);
        if (email) {
          amplitude
            .getInstance()
            .init('3eea9e81b1c6f807918855caeee585c2', email);
        }
      });
    } else
      Common.showErrorMessage(
        'Invalid activation link provided, Please request re-confirmation email from your app.'
      );
  }, []);

  let url = ROOT_URL + Account.ConfirmEmail + window.location.search;

  const confirmUser = () => {
    if (userEmail) {
      amplitude.getInstance().logEvent('confirm button clicked');
      window.location.replace(url);
    }
  };

  return (
    <div className="maincontainer">
      <div className="logoAndHeading">
        <div className="ConfirmUserLogo">
          <img
            src={logo}
            alt="Umii-logo"
            style={{ width: '111px', height: '111px' }}
          />
        </div>
        <h2 className="ConfirmationHeading">Umii Confirmation</h2>
      </div>
      {loading && (
        <div className="loadingContainer">
          <CircularProgress />
        </div>
      )}
      {!loading && userId && userEmail ? (
        <div className="bodyContainer">
          <div className="ConfirmationText">
            Please click the button bellow to confirm this is your email address
            and access the full Umii app! you will then be able to receive
            connections and make new friends at your university!
          </div>
          <div className="confirmBtnContainer">
            <Button
              classes={{ label: 'confirmBtn' }}
              onClick={() => {
                confirmUser();
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      ) : (
        !loading && (
          <div className="InvalidCls">
            Invalid activation link provided, Please request re-confirmation
            email from your app.
          </div>
        )
      )}
      <div className="footer">
        if you received this email by mistake, simply delete it.You won't be
        subscribed if you dont click the confirmation link above.
        <br></br>
        <br></br>
        for question about this link please contact:
        <br></br>
        <a href="info@umii.app">info@umii.app</a>
      </div>
    </div>
  );
};

export default ConfirmUserPage;
