import { actionTypes } from "../../../utils";
const { SOCIETY_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES } = SOCIETY_ACTIONS;

const INITIAL_STATE = {
    universities: [],
};

export default (state = INITIAL_STATE, action = {}) => {
    if (action.type === SET_UNIVERSITIES)
        return {
            ...state,
            universities: [...action.payload],
        };
    else {
        return state;
    }
};
