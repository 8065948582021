import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@mui/material';

const UmiiTextField = ({
  id,
  label,
  className,
  fieldType,
  marginType,
  isDisabled,
  isMultiLine,
  name,
  isRequired,
  value,
  onValueChange
}) => (
  <TextField
    id={id}
    label={label}
    className={className}
    type={fieldType}
    margin={marginType}
    fullWidth
    disabled={isDisabled}
    multiline={isMultiLine}
    name={name}
    required={isRequired}
    value={value}
    onChange={onValueChange}
  />
);

UmiiTextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  fieldType: PropTypes.string,
  marginType: PropTypes.string,
  isDisabled: PropTypes.bool,
  isMultiLine: PropTypes.bool,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func
};
UmiiTextField.defaultProps = {
  id: '',
  label: '',
  className: '',
  fieldType: 'text',
  marginType: 'none',
  isDisabled: false,
  isMultiLine: false,
  name: '',
  isRequired: false,
  onValueChange: () => {}
};
export { UmiiTextField };
