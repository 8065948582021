import React from 'react';
import loaderImg from '../../../resources/images/loading.gif';
import PropTypes from 'prop-types';

const Loader = ({ styles }) => (
  <div style={styles}>
    <img width={35} src={loaderImg} />
  </div>
);

Loader.propTypes = {
  styles: PropTypes.any
};

export { Loader };
