/* eslint-disable comma-dangle */
import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loader, Totalizer, UmiiButton } from '../../common';
import * as applicantActions from './applicantActions';
import { Common } from '../../../utils';
import PropTypes from 'prop-types';
import { GridView } from '../../common';
import { getFormatedDate } from '../../../utils';
import { Dialog, TableRow, TableCell } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import UpdateApplicant from '../../user/applicant/updateApplicant';

const Applicants = ({
  applicants,
  applicantActions: { onGetUniverities, onUploadApplicants, getApplicants }
}) => {
  const noneUni = -1;
  const { applicantList } = applicants;
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedFile, setselectedFile] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [fileKey, setFileKey] = useState('');
  const [applicantCount, setApplicantCount] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [applicantId, setApplicantId] = useState();

  useEffect(() => {
    onGetUniverities();
  }, []);

  useEffect(() => {
    selectedUniversity?.Id && getApplicants(selectedUniversity.Id);
  }, [selectedUniversity]);

  const handleUniversityChange = (selectedUniversity) => {
    setSelectedUniversity(selectedUniversity);
    if (isUniSelected(selectedUniversity)) {
      applicantActions.onGetAppicationCount(selectedUniversity.Id).then((response) => {
        if (response.data.success) {
          setApplicantCount(response.data.data);
        }
      });
    }
  };

  const onFileChangeHandler = (event) => {
    setselectedFile(event.target.files[0]);
  };

  const onUploadFile = async () => {
    const uniId = selectedUniversity.Id;
    if (!selectedUniversity || uniId === noneUni) {
      Common.showErrorMessage('Please select a university first');
      return;
    }
    if (!selectedFile) {
      Common.showErrorMessage('Please select a file first');
      return;
    }

    setIsUploadingFile(true);
    const response = await onUploadApplicants(uniId, selectedFile);
    await getApplicants(uniId);
    setIsUploadingFile(false);

    response.success && resetForm();
  };

  const resetForm = () => {
    const fKey = Math.random().toString(36); //NOSONAR
    setFileKey(fKey);
    setselectedFile(null);
  };

  const closeUpdateModal = () => {
    setUpdateModal(false);
  };

  const gridHeaders = [
    { id: 1, name: 'University Id', prop: 'UniversityId', numeric: false },
    { id: 2, name: 'Email', prop: 'Email', numeric: false },
    { id: 3, name: 'Created Date', prop: 'CreatedOn', numeric: false },
    { id: 4, name: 'Expiry Date', prop: 'VerificationExpiryDate', numeric: false },
    { id: 5, name: 'Status', prop: 'IsDeleted', numeric: false },
    { id: 6, name: 'Edit', numeric: false }
  ];

  const renderRowItem = useCallback((applicant) => {
    const { Id, UniversityId, Email, CreatedOn, VerificationExpiryDate, IsDeleted } = applicant;

    return (
      <TableRow key={Id} onClick={handleRowClick(Id, IsDeleted)}>
        <TableCell>{UniversityId}</TableCell>
        <TableCell>{Email}</TableCell>
        <TableCell>{getFormatedDate(CreatedOn)}</TableCell>
        <TableCell>{getFormatedDate(VerificationExpiryDate)}</TableCell>
        <TableCell>{IsDeleted ? 'Deleted' : ''}</TableCell>
        <TableCell>
          <EditIcon onClick={higherOrder_handleRowEditClick(Id)} />
        </TableCell>
      </TableRow>
    );
  }, []);

  const handleRowClick = (applicantId, isDeleted) => (e) => {
    !isDeleted && handleRowEditClick(e, applicantId);
  };

  const higherOrder_handleRowEditClick = (applicantId) => (e) => {
    handleRowEditClick(e, applicantId);
  };

  const handleRowEditClick = (e, applicantId) => {
    e.stopPropagation();
    setUpdateModal(true);
    setApplicantId(applicantId);
  };

  const isUniSelected = (uni) => uni && uni.Id !== noneUni;
  const universityHasData = useCallback(() => applicantList.length > 0, [applicantList]);

  return (
    <div className="main-wrapper-applicant">
      {/* Uploading Section */}
      <div className="upload-section">
        <Select
          getOptionLabel={(option) => `${option.Name}`}
          getOptionValue={(option) => `${option.Id}`}
          value={selectedUniversity}
          onChange={handleUniversityChange}
          options={applicants.universities}
        />
        <div className="upload-section-buttons">
          <input
            key={fileKey || ''}
            type="file"
            onChange={onFileChangeHandler}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />{' '}
          {isUploadingFile ? (
            <Loader styles={{ float: 'right', marginTop: 15 }} />
          ) : (
            <UmiiButton
              disabled={!selectedFile}
              variant="contained"
              className="uploadBtn"
              btnText="Upload"
              style={{ float: 'right' }}
              onClick={onUploadFile}
            />
          )}
        </div>
      </div>
      {/* Totalizer */}
      {isUniSelected(selectedUniversity) && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Totalizer
            title={`Total Applicants For ${selectedUniversity.Name}`}
            counter={applicantCount}
          />
        </div>
      )}
      <br />
      {/* Grid */}
      {isUniSelected(selectedUniversity) && universityHasData(applicantList) && (
        <div className="tbl-wrapper">
          <div className="table tbl-applicants">
            <GridView
              dataList={applicantList}
              headers={gridHeaders}
              renderRowItem={renderRowItem}
            />
          </div>
        </div>
      )}
      <Dialog open={updateModal} onClose={closeUpdateModal}>
        <UpdateApplicant onClose={closeUpdateModal} applicantId={applicantId} />
      </Dialog>
    </div>
  );
};

Applicants.propTypes = {
  applicants: PropTypes.shape({
    universities: PropTypes.any
  }),
  applicantActions: PropTypes.shape({
    onGetUniverities: PropTypes.func,
    onUploadApplicants: PropTypes.func
  })
};

export default connect(
  (state) => ({
    applicants: state.applicants
  }),
  (dispatch) => ({
    applicantActions: bindActionCreators(applicantActions, dispatch)
  })
)(Applicants);
