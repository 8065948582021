import React from 'react';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const rowItem = (data, i, onClick) => {
  const res = Object.values(data);
  return (
    <TableRow key={`tr-${i}`}>
      {res.map((item, index) => {
        return (
          <React.Fragment key={`rf-${i}`}>
            {typeof item === 'string' ? (
              <Tooltip title={item}>
                <TableCell onClick={() => onClick(data)} key={`tc-${index}`}>
                  {typeof item === 'string' ? item.slice(0, 25) : item}
                </TableCell>
              </Tooltip>
            ) : (
              <TableCell onClick={() => onClick(data)} key={`tc-${index}`}>
                {typeof item === 'string' ? item.slice(0, 25) : item}
              </TableCell>
            )}
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

rowItem.propTypes = {
  data: PropTypes.any,
  i: PropTypes.any,
  onClick: PropTypes.func
};

export { rowItem };
