import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="ErrorPage">
      <div>
        <span className="errorLbl">404</span>
        <div>Oops, it looks like that page has floated away.</div>
        <a href="/">Take me home</a>
      </div>
    </div>
  );
};

export { NotFoundPage };
