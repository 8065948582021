import axios from 'axios';
import { config } from './endPoints';
import { WebStorage, WebStorageNames, messages, Common } from '../utils';

const { ROOT_URL, REST_API } = config;
const { Exception } = messages;

const axiosBasic = axios.create({
  baseURL: ROOT_URL,
  timeout: 60 * 1000,
  headers: { Pragma: 'no-cache' }
});

const issueToken = async (refreshToken) => {
  try {
    const { data } = await axiosBasic.post(
      `${REST_API.Account.RefreshToken}`,
      { RefreshToken: refreshToken },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return data;
  } catch (err) {
    const { response } = err;

    if (response && response.status === 400 && !response.data.success) {
      WebStorage.removeLocalStore(WebStorageNames.AuthInfo);
      WebStorage.removeLocalStore(WebStorageNames.UserInfo);
      Common.showErrorMessage(Exception.sessionExpired);
      window.location.href = '/';
      return; // early return to avoid further processing
    }

    throw err; //rejecct
  }
};

export const getAuthToken = () =>
  WebStorage.getLocalStore(WebStorageNames.AuthInfo);

export const configureAxiosDefaults = async () => {
  axiosBasic.defaults.baseURL = ROOT_URL;
  axiosBasic.defaults.headers.post['Content-Type'] = 'application/json';

  axiosBasic.interceptors.request.use(
    (configuration) => {
      const cfg = configuration;
      cfg.headers.Pragma = 'no-cache';
      const url = cfg.url.toLowerCase();
      if (url.endsWith('login') || url.endsWith('forgotpassword')) {
        return cfg;
      }
      const res = getAuthToken();
      if (res !== null) {
        cfg.headers.Authorization = `Bearer ${res.AccessToken}`;
      }
      return Promise.resolve(cfg);
    },
    (err) => {
      Promise.reject(err);
    }
  );
};

export const axiosResponse = () => {
  axiosBasic.interceptors.response.use(null, async (errResp) => {
    const { config, response, message } = errResp;
    const { status, data } = response || {};

    // Token-Expired
    if (config && status === 401) {
      const authTokens = getAuthToken();

      if (authTokens) {
        try {
          // Get new token from API
          const tokenResponse = await issueToken(authTokens.RefreshToken);
          const { AccessToken, RefreshToken } = tokenResponse.data;

          // Update the stored auth tokens
          const newAuthData = { AccessToken, RefreshToken };
          WebStorage.setLocalStore(WebStorageNames.AuthInfo, newAuthData);

          // Retry the original request
          return Promise.resolve(axiosBasic.request(config));
        } catch (tokenError) {
          return Promise.reject(tokenError);
        }
      }
      return Promise.reject(error);
    }

    if (status === 400) {
      Common.showErrorMessage(data.message);
    }

    if (message === Exception.networkError) {
      return Promise.reject(errResp);
    }

    return Promise.reject(errResp);
  });
};

export { axiosBasic };
