import { actionTypes, Common, messages } from '../../../utils';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';
import { AppSettingKeys } from '../../../utils/constants';

const { AppDbSettings } = config.REST_API;
const { APPSETTING_ACTIONS } = actionTypes;
const {
  Errors: { requestFailed, noDataReturned }
} = messages;
const { SET_ALL, SET_EMAILS_TO_UNVERIFY } = APPSETTING_ACTIONS;
const { EMAILS_TO_UNVERIFY, VERIFICATION_EXPIRY_WARNING_THRESHOLD } =
  AppSettingKeys;

export const setAllApplicationSettings = (value) => ({
  type: SET_ALL,
  payload: value
});

export const setEmailsToUnverify = (value) => ({
  type: SET_EMAILS_TO_UNVERIFY,
  payload: value
});

export const setBannerTimeout = (value) => ({
  type: SET_VERIFICATION_EXPIRY_WARNING_THRESHOLD,
  payload: value
});

export const getAllAppSettings = () => async (dispatch) => {
  try {
    const { data } = await axiosBasic.get(AppDbSettings.AppDbSettings);

    if (data) {
      dispatch(setAllApplicationSettings(data));
    } else {
      Common.showErrorMessage(noDataReturned);
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateAppSettings = (appSettingKey, value) => async (dispatch) => {
  const formData = new FormData();
  formData.append('newValue', value);

  try {
    const resp = await axiosBasic.put(
      `${AppDbSettings.AppDbSettings}/${appSettingKey}`,
      formData
    );

    const { success } = resp.data;

    if (success) {
      if (appSettingKey == EMAILS_TO_UNVERIFY)
        await dispatch(setEmailsToUnverify(value));
      else if (appSettingKey == VERIFICATION_EXPIRY_WARNING_THRESHOLD)
        await dispatch(setBannerTimeout(value));
    } else {
      Common.showErrorMessage(requestFailed);
    }
  } catch (err) {
    console.error(err);
  }
};
