import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';
import logo from '../../resources/images/umii-logo2.png';
import { onLogOut } from '../accounts/accountActions';
import { Dialog, ClickAwayListener, MenuItem } from '@mui/material';
import AddUniversityForm from './forms/AddUniversityForm';
import { Add as AddIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

const HeaderView = ({ dashboard: { headerTitle }, logOut }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [addNewUniversityModel, setAddNewUniversityModel] = useState(false);

  const onCloseAddNewUniversityDialog = () => {
    setAddNewUniversityModel(false);
  };

  return (
    <React.Fragment>
      <div className="headerView">
        <div className="logoCnt">
          <NavLink
            to="/dashboard"
            className={(navData) => (navData.isActive ? 'activeCls' : '')}
          >
            <img src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="headerTitle">{headerTitle}</div>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={(navData) => (navData.isActive ? 'activeCls' : '')}
              onClick={() => setIsOpen(!isOpen)}
            >
              Universities
            </NavLink>
            {isOpen && (
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <span className="dropDown">
                  <MenuItem onClick={() => setAddNewUniversityModel(true)}>
                    <AddIcon />
                    Add University
                  </MenuItem>
                </span>
              </ClickAwayListener>
            )}
          </li>
          <li>
            <NavLink
              to="/applicants"
              className={(navData) => (navData.isActive ? 'activeCls' : '')}
            >
              Pre-verification
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/societies"
              className={(navData) => (navData.isActive ? 'activeCls' : '')}
            >
              Clubs And Societies
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/inbox"
              className={(navData) => (navData.isActive ? 'activeCls' : '')}
            >
              Inbox
            </NavLink>
          </li>
          <li
            tabIndex={0}
            onClick={() => logOut(navigate)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                logOut(navigate);
              }
            }}
          >
            <NavLink
              to="#"
              className={(navData) => (navData.isActive ? 'activeCls' : '')}
            >
              Logout
            </NavLink>
          </li>
        </ul>
      </div>
      <Dialog
        open={addNewUniversityModel}
        onClose={onCloseAddNewUniversityDialog}
      >
        <AddUniversityForm onClose={onCloseAddNewUniversityDialog} />
      </Dialog>
    </React.Fragment>
  );
};

HeaderView.propTypes = {
  logOut: PropTypes.func,
  dashboard: PropTypes.shape({
    headerTitle: PropTypes.string
  })
};

const screen = connect(
  (state) => ({
    dashboard: state.dashboard
  }),
  (dispatch) => ({
    logOut: (navigation) => {
      dispatch(onLogOut(navigation));
    }
  })
)(HeaderView);
export { screen as HeaderView };
