import PropTypes from 'prop-types';
import React from 'react';


const UmiiSearchField = ({
  id, className, fieldType, value, onValueChange,
}) => (
  <div className={className}>
    <input id={id} type={fieldType} placeholder="Search" value={value} onChange={onValueChange} />
  </div>
);

UmiiSearchField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  fieldType: PropTypes.string,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
};
UmiiSearchField.defaultProps = {
  id: '',
  className: 'search',
  fieldType: 'search',
  onValueChange: () => {},

};
export { UmiiSearchField };
