import React from 'react';
import { HeaderView } from '.';
import PropTypes from 'prop-types';

const Main = (props) => {
  const { children } = props;
  return (
    <div className="dashContainer">
      <HeaderView />
      <div>{children}</div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.any
};

export { Main };
