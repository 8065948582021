import { actionTypes, WebStorage, WebStorageNames, Common } from '../../utils';
import { axiosBasic, getAuthToken } from '../../api/axios';
import { RouteNames } from '../../router/routeNames';
import { config } from '../../api/endPoints';

const { ACCOUNT_ACTIONS } = actionTypes;
const { Account } = config.REST_API;

export const loginFormChanged = (prop, value) => ({
  type: ACCOUNT_ACTIONS.LOGIN_FORM_CHANGED,
  payload: { prop, value }
});
export const logOut = () => ({
  type: ACCOUNT_ACTIONS.LOG_OUT
});
const setUserdata = (userInfo, authData) => {
  WebStorage.setLocalStore(WebStorageNames.UserInfo, userInfo);
  WebStorage.setLocalStore(WebStorageNames.AuthInfo, authData);
};

export const onLoginUser =
  ({ email, password, navigation }) =>
  (dispatch) => {
    axiosBasic
      .post(Account.LogIn, {
        Email: email,
        Password: password
      })
      .then((response) => {
        const { success, data, message } = response.data;
        if (success) {
          dispatch(loginFormChanged('isLoggedIn', true));
          const { AccessToken, RefreshToken, Role, DeviceIdentifer } = data;
          const userInfo = {
            Role,
            email
          };
          const authData = { AccessToken, RefreshToken, DeviceIdentifer };
          setUserdata(userInfo, authData);
          navigation(RouteNames.Dashboard);
        } else {
          Common.showErrorMessage(message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

const clearStorage = (navigation) => {
  WebStorage.removeLocalStore(WebStorageNames.AuthInfo);
  WebStorage.removeLocalStore(WebStorageNames.UserInfo);
  navigation(RouteNames.Login);
};
export const onLogOut = (navigation) => (dispatch) => {
  try {
    axiosBasic
      .get(`${Account.LogOff + getAuthToken().DeviceIdentifer}&deviceToken=' '`)
      .then(() => {
        dispatch(logOut());
        clearStorage(navigation);
      })
      .catch((error) => {
        console.log(error);
        dispatch(logOut());
        clearStorage(navigation);
      });
  } catch (ex) {
    dispatch(logOut());
    clearStorage(navigation);
  }
};
