import PropTypes from 'prop-types';
import React from 'react';
import { Edit as EditIcon, Cancel as CancelIcon } from '@mui/icons-material';

const Domains = ({
  DomainsList,
  onAddDomainClick,
  onDeleteDomainClick,
  onEditDomainClick
}) => (
  <div className="date">
    <div>
      <p>Custom Domains</p>
      {DomainsList.map((d) => (
        <div
          key={d.Id}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <p>{`@${d.Name}`}</p>
          <span>
            <EditIcon
              className="yellowEmail"
              style={{ cursor: 'pointer', marginRight: '10px' }}
              onClick={() => onEditDomainClick(d)}
            />
            <CancelIcon
              style={{ cursor: 'pointer', color: '#FB3904' }}
              onClick={() => onDeleteDomainClick(d)}
            />
          </span>
        </div>
      ))}
      <button onClick={onAddDomainClick}>{'Add Domain'}</button>
    </div>
  </div>
);

Domains.propTypes = {
  DomainsList: PropTypes.array,
  onAddDomainClick: PropTypes.func,
  onDeleteDomainClick: PropTypes.func,
  onEditDomainClick: PropTypes.func
};

Domains.defaultProps = {
  onAddDomainClick: () => {},
  onDeleteDomainClick: () => {},
  onEditDomainClick: () => {}
};
export { Domains };
