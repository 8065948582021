import React, { useState, useRef, useCallback, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import * as appSettingActions from './appSettingActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppSettingKeys } from '../../../utils/constants';
import PropTypes from 'prop-types';

const { EMAILS_TO_UNVERIFY, VERIFICATION_EXPIRY_WARNING_THRESHOLD } = AppSettingKeys;

const VerificationPanel = ({ state, actions: { getAllAppSettings, updateAppSettings } }) => {
  const emailVal = state.appSettings[EMAILS_TO_UNVERIFY];
  const bannerVal = state.appSettings[VERIFICATION_EXPIRY_WARNING_THRESHOLD];

  const [emailsToUnverify, setEmailsToUnverify] = useState(emailVal);
  const [bannerTimeout, setBannerTimeout] = useState(bannerVal);

  const prevEmailsToUnverify = useRef(emailVal);
  const prevBannerTimeout = useRef(bannerVal);

  useEffect(() => {
    if (emailVal !== prevEmailsToUnverify.current) {
      setEmailsToUnverify(emailVal);
      prevEmailsToUnverify.current = emailVal;
    }
  }, [emailVal]);

  useEffect(() => {
    if (bannerVal !== prevBannerTimeout.current) {
      setBannerTimeout(bannerVal);
      prevBannerTimeout.current = bannerVal;
    }
  }, [bannerVal]);

  useEffect(() => {
    getAllAppSettings();
  }, [updateAppSettings]);

  const handleChange = (e, setInput) => setInput(e.target.value);

  const handleBlur = (newTxt, prevTxtRef, appSettingKey) => {
    if (newTxt !== prevTxtRef.current) {
      prevTxtRef.current = newTxt;
      updateAppSettings(appSettingKey, newTxt);
    }
  };

  const handleChangeEmails = useCallback((e) => handleChange(e, setEmailsToUnverify), []);
  const handleChangeBannerTimeout = useCallback((e) => handleChange(e, setBannerTimeout), []);

  const handleBlurEmails = useCallback(
    () => handleBlur(emailsToUnverify, prevEmailsToUnverify, EMAILS_TO_UNVERIFY),
    [emailsToUnverify]
  );
  const handleBlurBannerTimeout = useCallback(
    () => handleBlur(bannerTimeout, prevBannerTimeout, VERIFICATION_EXPIRY_WARNING_THRESHOLD),
    [bannerTimeout]
  );

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <div className="date brnadingWrapper">
        <div className="brandingHeader">
          <h2>Verificaiton Panel</h2>
        </div>

        <div>Emails to Reverify</div>
        <TextField
          multiline
          rows={8}
          variant="outlined"
          fullWidth
          value={emailsToUnverify}
          onChange={handleChangeEmails}
          onBlur={handleBlurEmails}
          sx={{
            '& .MuiInputBase-root': {
              resize: 'both',
              overflow: 'auto',
              maxWidth: '100%',
              maxHeight: '100%'
            }
          }}
        />

        <div>Re-verification Banner Timeout</div>
        <TextField
          value={bannerTimeout}
          onChange={handleChangeBannerTimeout}
          onBlur={handleBlurBannerTimeout}
        ></TextField>
      </div>
    </div>
  );
};

VerificationPanel.propTypes = {
  state: PropTypes.shape({
    appSettings: PropTypes.object.isRequired
  }).isRequired,
  actions: PropTypes.shape({
    getAllAppSettings: PropTypes.func.isRequired,
    updateAppSettings: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  state: state.appSettings
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appSettingActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationPanel);
