import React from 'react';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

export default function AreaOfStudyDialog({ open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogBoxCls adminDialog"
      >
        <DialogTitle id="alert-dialog-title">Update your campus</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <input
              type="text"
              placeholder="Please enter campus"
              className="textField"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogButtons">
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            className="confirmBtn"
          >
            Add
          </Button>
        </DialogActions>

        <div className="gridWrapper">
          <ul className="campusGrid">
            <li>Campus name </li>
            <li>Action</li>
          </ul>
          <ul className="campusGrid">
            <li>Campus name </li>
            <li className="gridIcons">
              <EditIcon className="editResult" />
              <DeleteIcon />
            </li>
          </ul>
          <ul className="campusGrid">
            <li>Campus name </li>
            <li className="gridIcons">
              <EditIcon className="editResult" />
              <DeleteIcon />
            </li>
          </ul>
          <ul className="campusGrid">
            <li>Campus name </li>
            <li className="gridIcons">
              <EditIcon className="editResult" />
              <DeleteIcon />
            </li>
          </ul>
        </div>
      </Dialog>
    </div>
  );
}

AreaOfStudyDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
