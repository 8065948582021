import React, { useEffect, useState } from 'react';
import {
  Dialog,
  TextField,
  FormControlLabel,
  Button,
  Backdrop,
  CircularProgress,
  Checkbox
} from '@mui/material';
import { DialogBox } from '../../common/dialogBox/dialogBox';
import { Common, messages } from '../../../utils';
import { Edit as EditIcon } from '@mui/icons-material';
import { Formik, Form, ErrorMessage } from 'formik';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const { validation } = messages;

const DetailDialogBox = ({
  openDialog,
  onCancel,
  onConfirm,
  details,
  onUpdate
}) => {
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [studentDetail, setStudentDetail] = useState({
    UserName: '',
    Email: '',
    EmailConfirmed: '',
    AccessFailedCount: '',
    LockoutEnabled: '',
    Location: '',
    UniversityId: '',
    Name: '',
    IsUniApproved: '',
    ProfilePicture: '',
    CoverPicture: '',
    IsProfileCompleted: '',
    ProfileCompletedDate: '',
    Bio: '',
    Identity: '',
    Ethnicity: '',
    Sexuality: '',
    Loan: '',
    Alcohol: '',
    Children: '',
    Responsibilities: '',
    Iam: '',
    Accomodation: '',
    Course: '',
    CourseName: '',
    CourseYear: '',
    Age: '',
    IsFirstMatched: '',
    IsGeneratingConnections: '',
    ResendConfirmation: '',
    UpdateOn: '',
    LastActiveDate: '',
    VerifiedBy: '',
    PersonalityQuestion: '',
    TermConditionPrivacyPolicyAcceptedOn: ''
  });
  const Icon = <EditIcon />;
  const { User } = config.REST_API;

  const onUserConfirm = () => {
    onConfirm();
    setOpenConfirmDialog(false);
  };

  const validateSchema = Yup.object().shape({
    UserName: Yup.string()
      .email(validation.invalidEmail)
      .required(validation.requiredField),
    Email: Yup.string()
      .email(validation.invalidEmail)
      .required(validation.requiredField)
  });
  useEffect(() => {
    axiosBasic({
      url: User.GetUserDetail,
      method: 'get',
      params: {
        email: details.OtherEmail
      }
    }).then((res) => {
      setStudentDetail({
        UserName: res.data.data.UserName,
        Email: res.data.data.Email,
        EmailConfirmed: res.data.data.EmailConfirmed,
        AccessFailedCount: res.data.data.AccessFailedCount,
        LockoutEnabled: res.data.data.LockoutEnabled,
        Location: res.data.data.Location,
        UniversityId: res.data.data.UniversityId,
        Name: res.data.data.Name,
        IsUniApproved: res.data.data.IsUniApproved,
        ProfilePicture: res.data.data.ProfilePicture,
        CoverPicture: res.data.data.CoverPicture,
        IsProfileCompleted: res.data.data.IsProfileCompleted,
        ProfileCompletedDate: res.data.data.ProfileCompletedDate,
        Bio: res.data.data.Bio,
        Identity: res.data.data.Identity,
        Ethnicity: res.data.data.Ethnicity,
        Sexuality: res.data.data.Sexuality,
        Loan: res.data.data.Loan,
        Alcohol: res.data.data.Alcohol,
        Children: res.data.data.Children,
        Responsibilities: res.data.data.Responsibilities,
        Iam: res.data.data.Iam,
        Accomodation: res.data.data.Accomodation,
        Course: res.data.data.Course,
        CourseName: res.data.data.CourseName,
        CourseYear: res.data.data.CourseYear,
        Age: res.data.data.Age,
        IsFirstMatched: res.data.data.IsFirstMatched,
        IsGeneratingConnections: res.data.data.IsGeneratingConnections,
        ResendConfirmation: res.data.data.ResendConfirmation,
        UpdateOn: res.data.data.UpdateOn,
        LastActiveDate: res.data.data.LastActiveDate,
        VerifiedBy: res.data.data.VerifiedBy,
        PersonalityQuestion: res.data.data.PersonalityQuestion,
        TermConditionPrivacyPolicyAcceptedOn:
          res.data.data.TermConditionPrivacyPolicyAcceptedOn
      });
      setChecked(res.data.data.Banned);
    });
  }, []);

  const handleSaveChanges = (value) => {
    setLoader(true);
    axiosBasic({
      url: User.UpdateUserDetail,
      method: 'PUT',
      params: {
        email: details.OtherEmail
      },
      data: {
        UserName: value.UserName,
        Email: value.Email,
        Banned: checked
      }
    })
      .then((response) => {
        const { success, message } = response.data;
        if (success) {
          onUpdate();
          onCancel();
          setLoader(true);
          Common.showSuccessMessage('User updated successfully');
        } else {
          Common.showErrorMessage(message);
        }
      })
      .catch((error) => {
        console.log(error);
        Common.showErrorMessage('Something went wrong, please try again');
        setLoader(true);
      });
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Backdrop style={{ zIndex: '1' }} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        onClose={onCancel}
        aria-labelledby="customized-dialog-title"
        className="detailDialogCls"
      >
        <Formik
          initialValues={studentDetail}
          validationSchema={validateSchema}
          onSubmit={handleSaveChanges}
          enableReinitialize={true}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <div className="userDetailDialog">
                <TextField
                  name="UserName"
                  type="text"
                  label="UserName"
                  variant="standard"
                  value={values.UserName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: Icon
                  }}
                />
                <span style={{ color: 'red' }}>
                  <ErrorMessage name="UserName" />
                </span>
                <TextField
                  name="Email"
                  type="text"
                  label="Email"
                  variant="standard"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: Icon
                  }}
                />
                <span style={{ color: 'red' }}>
                  <ErrorMessage name="Email" />
                </span>
                <TextField
                  name="EmailConfirmed"
                  type="bool"
                  label="EmailConfirmed"
                  variant="standard"
                  value={values.EmailConfirmed}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="EmailConfirmed" />
                <TextField
                  name="AccessFailedCount"
                  label="AccessFailedCount"
                  value={values.AccessFailedCount}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="AccessFailedCount" />
                <TextField
                  name="LockoutEnabled"
                  type="bool"
                  label="LockoutEnabled"
                  value={values.LockoutEnabled}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="LockoutEnabled" />
                <TextField
                  name="Location"
                  type="text"
                  label="University name"
                  value={values.Location}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Location" />
                <TextField
                  name="UniversityId"
                  label="UniversityId"
                  value={values.UniversityId}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="UniversityId" />
                <TextField
                  name="Name"
                  type="text"
                  label="Name"
                  value={values.Name}
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Name" />
                <TextField
                  name="IsUniApproved"
                  type="bool"
                  label="IsUniApproved"
                  variant="standard"
                  value={values.IsUniApproved}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="IsUniApproved" />
                <TextField
                  name="ProfilePicture"
                  type="text"
                  label="ProfilePicture"
                  variant="standard"
                  value={values.ProfilePicture}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="ProfilePicture" />
                <TextField
                  name="CoverPicture"
                  type="text"
                  label="CoverPicture"
                  variant="standard"
                  value={values.CoverPicture}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="CoverPicture" />
                <TextField
                  name="IsProfileCompleted"
                  type="bool"
                  label="IsProfileCompleted"
                  variant="standard"
                  value={values.IsProfileCompleted}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="IsProfileCompleted" />
                <TextField
                  name="ProfileCompletedDate"
                  type="text"
                  label="ProfileCompletedDate"
                  variant="standard"
                  value={values.ProfileCompletedDate}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="ProfileCompletedDate" />
                <TextField
                  name="Bio"
                  type="text"
                  label="Bio"
                  variant="standard"
                  value={values.Bio}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Bio" />
                <TextField
                  name="Identity"
                  type="text"
                  label="Identity"
                  variant="standard"
                  value={values.Identity}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Identity" />
                <TextField
                  name="Ethnicity"
                  type="text"
                  label="Ethnicity"
                  variant="standard"
                  value={values.Ethnicity}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Ethnicity" />
                <TextField
                  name="Sexuality"
                  type="text"
                  label="Sexuality"
                  variant="standard"
                  value={values.Sexuality}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Sexuality" />
                <TextField
                  name="Loan"
                  type="text"
                  label="Loan"
                  variant="standard"
                  value={values.Loan}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Loan" />
                <TextField
                  name="Alcohol"
                  type="text"
                  label="Alcohol"
                  variant="standard"
                  value={values.Alcohol}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Alcohol" />
                <TextField
                  name="Children"
                  type="text"
                  label="Children"
                  variant="standard"
                  value={values.Children}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Children" />
                <TextField
                  name="Responsibilities"
                  type="text"
                  label="Responsibilities"
                  variant="standard"
                  value={values.Responsibilities}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Responsibilities" />
                <TextField
                  name="Iam"
                  type="text"
                  label="Iam "
                  variant="standard"
                  value={values.Iam}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Iam" />
                <TextField
                  name="Accomodation"
                  type="text"
                  label="Accomodation"
                  variant="standard"
                  value={values.Accomodation}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Accomodation" />
                <TextField
                  name="Course"
                  type="text"
                  label="Course"
                  variant="standard"
                  value={values.Course}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <TextField
                  name="CourseName"
                  type="text"
                  label="CourseName"
                  variant="standard"
                  value={values.CourseName}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="CourseName" />
                <TextField
                  name="CourseYear"
                  type="text"
                  label="Course Year"
                  variant="standard"
                  value={values.CourseYear}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="CourseYear" />
                <TextField
                  name="Age"
                  label="Age"
                  variant="standard"
                  value={values.Age}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="Age" />
                <TextField
                  name="IsFirstMatched "
                  type="bool"
                  label="IsFirstMatched"
                  variant="standard"
                  value={values.IsFirstMatched}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="IsFirstMatched" />
                <TextField
                  name="IsGeneratingConnections"
                  type="bool"
                  label="IsGeneratingConnections"
                  variant="standard"
                  value={values.IsGeneratingConnections}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="IsGeneratingConnections" />
                <TextField
                  name="ResendConfirmation"
                  label="ResendConfirmation"
                  variant="standard"
                  value={values.ResendConfirmation}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="ResendConfirmation" />
                <TextField
                  name="UpdateOn"
                  type="text"
                  label="UpdateOn"
                  variant="standard"
                  value={values.UpdateOn}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="UpdateOn" />
                <TextField
                  name="LastActiveDate"
                  // type="text"
                  label="LastActiveDate"
                  variant="standard"
                  value={values.LastActiveDate}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="LastActiveDate" />
                <TextField
                  name="VerifiedBy"
                  label="VerifiedBy"
                  variant="standard"
                  value={values.VerifiedBy}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="VerifiedBy" />
                <TextField
                  name="PersonalityQuestion"
                  type="text"
                  label="PersonalityQuestion"
                  variant="standard"
                  value={values.PersonalityQuestion}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="PersonalityQuestion" />
                <TextField
                  name="TermConditionPrivacyPolicyAcceptedOn"
                  type="text"
                  label="TermConditionPrivacyPolicyAcceptedOn"
                  variant="standard"
                  value={values.TermConditionPrivacyPolicyAcceptedOn}
                  InputProps={{
                    readOnly: true,
                    style: { color: 'lightgray' }
                  }}
                />
                <ErrorMessage name="TermConditionPrivacyPolicyAcceptedOn" />
              </div>
              <div className="btnWrapper">
                <div className="detailDialogButton">
                  <Button
                    variant="outlined"
                    className="SaveBtnCls"
                    type="submit"
                  >
                    Save changes
                  </Button>
                  <Button
                    className="manuallyBtnCls"
                    variant="outlined"
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    Manually verify
                  </Button>
                  <FormControlLabel
                    classes={{ label: 'BannedCheckboxLabel' }}
                    control={
                      <Checkbox
                        name="Banned"
                        checked={checked}
                        onChange={() => setOpen(true)}
                        onClick={handleCheckboxChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        className="BannedCheckbox"
                        value={values.Banned}
                      />
                    }
                    label="Banned"
                  />
                </div>
              </div>
              <DialogBox
                openDialog={open}
                text={
                  checked
                    ? ` Do you want to ban ${details.OtherEmail} ?`
                    : ` Do you want to unban ${details.OtherEmail} ?`
                }
                onConfirm={() => handleSaveChanges(values)}
                onCancel={handleClose}
                heading="Confirm User Banning"
              />
            </Form>
          )}
        </Formik>
      </Dialog>
      <DialogBox
        openDialog={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={onUserConfirm}
      />
    </React.Fragment>
  );
};

DetailDialogBox.propTypes = {
  openDialog: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  details: PropTypes.shape({
    OtherEmail: PropTypes.string
  }),
  onUpdate: PropTypes.func
};

export default DetailDialogBox;
