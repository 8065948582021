import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import { axiosBasic, getAuthToken } from '../../../api/axios';
import { config } from '../../../api/endPoints';
import { Common } from '../../../utils';
import { Close as CloseIcon } from '@mui/icons-material';
import * as dashboardActions from '../../user/dashboard/dashboardActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

const AddUniversityForm = ({ onClose, ...props }) => {
  const [universityInfo, setUniversityInfo] = useState({
    Name: '',
    isSsoRequired: false
  });

  const [validateForm, setValidateForm] = useState({
    UniNameInValid: false,
    UniversityLength: false
  });
  const [uniExists, setUniExists] = useState(false);
  const [loader, setLoader] = useState(false);

  const { University } = config.REST_API;

  useEffect(() => {
    if (universityInfo.Name.length >= 255) {
      setValidateForm((prev) => ({ ...prev, UniversityLength: true }));
    } else {
      setValidateForm((prev) => ({ ...prev, UniversityLength: false }));
    }
  }, [universityInfo]);

  const handleChange = (e) => {
    e.persist();
    if (e.target.name === 'Name') {
      if (uniExists) {
        setUniExists(false);
      }
    }
    setUniversityInfo((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value
    }));
  };
  const handleSubmit = () => {
    const abc = getAuthToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${abc.AccessToken}`
    };
    setLoader(true);
    axiosBasic({
      url: University.University,
      method: 'post',
      data: {
        Name: universityInfo.Name.trim(),
        IsSsoRequired: universityInfo.isSsoRequired
      },
      headers
    })
      .then((res) => {
        setLoader(false);
        if (res.data.message === 'Uni Already Exists.') {
          Common.showErrorMessage('University already exists');
          setUniExists(true);
        } else {
          props.dashboardActions.onGetUniverities();
          Common.showSuccessMessage('University added successfully.');
          setUniExists(true);
        }
      })
      .catch(() => {
        Common.showErrorMessage('University not added.');
      });
  };
  return (
    <div className="outlineContainer">
      <div className="formContainer">
        <h2>Create New University</h2>
        <form autoComplete="off">
          <TextField
            onChange={handleChange}
            name={'Name'}
            className="formTextField"
            label="University Name"
            value={universityInfo.Name}
            fullWidth
            required
          />
          {validateForm.UniversityLength && (
            <span className="errorMessage">
              Name must be less than 255 characters
            </span>
          )}
          <FormControlLabel
            onChange={(e) => handleChange(e)}
            value={universityInfo.isSsoRequired}
            name={'isSsoRequired'}
            className="formCheckbox"
            control={
              <Checkbox
                style={{
                  color: 'white'
                }}
              />
            }
            label="IsSsoRequired"
            classes={{ label: 'checkBoxLabel' }}
          />
          <div className="divFormButton">
            <Button
              className="formButton"
              onClick={() => handleSubmit()}
              disabled={!universityInfo.Name || uniExists}
              classes={{ disabled: 'disabledCls' }}
            >
              {loader ? (
                <CircularProgress classes={{ circle: 'loader' }} size="30px" />
              ) : (
                'Submit'
              )}
            </Button>
          </div>
        </form>
      </div>
      <div style={{ marginTop: '-40px', cursor: 'pointer' }}>
        <CloseIcon fontSize="large" onClick={onClose} />
      </div>
    </div>
  );
};

AddUniversityForm.propTypes = {
  onClose: PropTypes.func,
  dashboardActions: PropTypes.shape({
    onGetUniverities: PropTypes.func
  })
};

export default connect(
  (state) => ({
    dashboard: state.dashboard
  }),
  (dispatch) => ({
    dashboardActions: bindActionCreators(dashboardActions, dispatch)
  })
)(AddUniversityForm);
