import React, { useState } from 'react';
import axios from 'axios';
import { WebStorage, WebStorageNames, Common } from '../../../../utils';
import { config } from '../../../../api/endPoints';
import { DialogBox, Loader } from '../../../common';
import { axiosBasic } from '../../../../api/axios';
const { REST_API, ROOT_URL } = config;

const client = axios.create({
  baseURL: ROOT_URL,
  timeout: 60 * 1000,
  headers: { Pragma: 'no-cache' }
});
const supportedFileFormat = ['png', 'PNG', 'jpg', 'JPG', 'JPEG', 'jpeg'];
const FormData = require('form-data');

export default function App() {
  const [image, setImage] = useState({
    preview: WebStorage.getLocalStore(WebStorageNames.UniversityLogo),
    raw: ''
  });
  const [isEditLogo, setIsEditLogo] = useState(
    WebStorage.getLocalStore(WebStorageNames.UniversityLogo) ? true : false
  );
  const [isUpdate, setIsUpdate] = useState(false);
  const [toggleDeleteDialogBox, settoggleDeleteDialogBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    if (e.target.files.length) {
      const uploadedFile = e.target.files[0];
      const fileExtensionList = uploadedFile.name.split('.');
      if (
        supportedFileFormat.indexOf(
          fileExtensionList[fileExtensionList.length - 1]
        ) !== -1
      ) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0]
        });
        setIsEditLogo(true);
        setIsUpdate(true);
      } else {
        Common.showErrorMessage(
          'File type not supported. Supported types are png, jpg'
        );
      }
    }
    window.removeEventListener('focus', handleFocusBack);
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    const formData = new FormData();
    formData.append('Id', universityId);
    formData.append('Logo', image.raw);
    axiosBasic({
      url: REST_API.University.SubmitUniversityLogo,
      method: 'post',
      data: formData
    })
      .then((response) => {
        setLoading(false);
        const { data } = response;
        if (data.success) {
          const fileUrl = data.data;
          const splittedUrl = fileUrl.split('?');
          setImage({
            raw: splittedUrl[0],
            preview: splittedUrl[0]
          });
          WebStorage.setLocalStore(
            WebStorageNames.UniversityLogo,
            splittedUrl[0]
          );
          Common.showSuccessMessage('University logo uploaded successfully.');
          setIsEditLogo(true);
          setIsUpdate(false);
        } else {
          Common.showErrorMessage('An error occured while uploading the logo.');
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const clickedFileInput = () => {
    window.addEventListener('focus', handleFocusBack);
  };
  const handleFocusBack = () => {
    if (image.preview) {
      setIsEditLogo(true);
    } else {
      setIsEditLogo(false);
    }
    window.removeEventListener('focus', handleFocusBack);
  };

  const handleDelete = async () => {
    setLoading(true);
    const universityId = WebStorage.getLocalStore(WebStorageNames.UniversityId);
    axiosBasic({
      url: REST_API.University.DeleteUniversityLogo,
      method: 'put',
      params: {
        uniId: universityId
      }
    })
      .then((response) => {
        setLoading(false);
        const { data } = response;
        if (data.success) {
          WebStorage.removeLocalStore(WebStorageNames.UniversityLogo);
          WebStorage.removeLocalStore(WebStorageNames.IsUniversityLogo);
          Common.showSuccessMessage('University logo deleted successfully.');
          settoggleDeleteDialogBox(false);
          setIsEditLogo(false);
          setIsUpdate(false);
          setImage({ preview: '', raw: '' });
        } else {
          Common.showErrorMessage('An error occured while Deleting the logo.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadLogoView = () => {
    if (isEditLogo) {
      return (
        <div className="brandingHeader">
          <img src={image.preview} alt="dummy" width="70" height="70" />
          <button
            className="logoUploadBtn"
            onClick={() => setIsEditLogo(false)}
          >
            Edit logo
          </button>
          <button
            className="logoRemoveBtn"
            onClick={() => {
              settoggleDeleteDialogBox(true);
            }}
          >
            Remove logo
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <span className="fa-stack fa-2x mt-3 mb-2">
            <i className="fas fa-circle fa-stack-2x" />
            <i className="fas fa-store fa-stack-1x fa-inverse" />
          </span>
          <h5 className="text-center">Please choose a file to uplaod</h5>
          <span
            style={{
              fontSize: 12,
              color: '#363636',
              display: 'block',
              width: '100%'
            }}
          >
            Image dimensions should be 50 x 50 pixels
          </span>
        </div>
      );
    }
  };

  return (
    <div>
      <label htmlFor="upload-button">
        {!loading ? (
          uploadLogoView()
        ) : (
          <Loader
            styles={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 15,
              marginBottom: 15,
              alignItem: 'center'
            }}
          />
        )}
      </label>
      <input
        type="file"
        id="upload-button"
        style={{ display: 'none' }}
        onChange={handleChange}
        onClick={clickedFileInput}
      />
      <DialogBox
        openDialog={toggleDeleteDialogBox}
        onCancel={() => settoggleDeleteDialogBox(false)}
        text={'Are you sure you want to delete this Logo!'}
        heading={'Delete Logo?'}
        onConfirm={handleDelete}
      />
      <br />
      {isUpdate && (
        <button onClick={handleUpload} className="logoUploadBtn">
          Upload
        </button>
      )}
    </div>
  );
}
