import { actionTypes } from '../../utils';
const { ACCOUNT_ACTIONS } = actionTypes;

const INITIAL_STATE = {
  initiated: true,
  isLoggedIn: false,
};

export default (state = INITIAL_STATE, action = {}, ) => {
  switch (action.type) {
    case ACCOUNT_ACTIONS.LOGIN_FORM_CHANGED:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    case ACCOUNT_ACTIONS.LOG_OUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
