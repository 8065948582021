import { actionTypes } from '../../../utils';
import { AppSettingKeys } from '../../../utils/constants';

const { APPSETTING_ACTIONS } = actionTypes;
const { EMAILS_TO_UNVERIFY } = AppSettingKeys;
const {
  SET_ALL,
  SET_EMAILS_TO_UNVERIFY,
  SET_VERIFICATION_EXPIRY_WARNING_THRESHOLD
} = APPSETTING_ACTIONS;

const INITIAL_STATE = {
  appSettings: {}
};

export default (state = INITIAL_STATE, action = {}) => {
  if (action.type === SET_ALL)
    return {
      ...state,
      appSettings: { ...action.payload }
    };
  else if (action.type === SET_EMAILS_TO_UNVERIFY) {
    return {
      ...state,
      appSettings: {
        ...state.appSettings,
        [EMAILS_TO_UNVERIFY]: action.payload
      }
    };
  } else if (action.type === SET_VERIFICATION_EXPIRY_WARNING_THRESHOLD) {
    return {
      ...state,
      appSettings: {
        ...state.appSettings,
        [VERIFICATION_EXPIRY_WARNING_THRESHOLD]: action.payload
      }
    };
  } else {
    return state;
  }
};
