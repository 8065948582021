import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Common } from '../../../utils';
import PropTypes from 'prop-types';

export default function ProfileSignifierDialog({
  open,
  handleClose,
  signifierList,
  onSignifierCreate,
  onSignifierUpdate,
  onDeleteSignifier
}) {
  const [title, setTitle] = useState('');
  const [type, setType] = useState('2');
  const [id, setId] = useState(0);
  const [isTitleError, setTitleError] = useState(false);
  const [dropMenus, setDropMenues] = useState('');
  const [btnText, setBtnText] = useState('Create');

  function validateSignifier() {
    if (title.trim() === '') {
      Common.showErrorMessage('Please enter signifier title.');
    } else if (type === '1' && dropMenus.trim() === '') {
      Common.showErrorMessage(
        'Please add dropdown options in comma separated.'
      );
    } else if (id === 0) {
      onSignifierCreate(title, type, dropMenus);
      clearFields();
    } else if (id > 0) {
      onSignifierUpdate(id, title, type, dropMenus);
      clearFields();
    }
  }

  function renderRowItem(data, index) {
    return (
      <ul className="campusGrid">
        <li>{data.Name} </li>
        <li className="gridIcons">
          <EditIcon onClick={() => onEditClick(data)} className="editResult" />
          <DeleteIcon onClick={() => onDeleteSignifier(data)} />
        </li>
      </ul>
    );
  }

  function onEditClick(item) {
    setBtnText('Update');
    setId(item.Id);
    setTitle(item.Name);
    setType(item.SignifierType + '');
    setDropMenues(getFormatedMenus(item.SignifierItems));
  }

  function clearFields() {
    setBtnText('Create');
    setId(0);
    setTitle('');
    setType('2');
    setDropMenues('');
  }

  function getFormatedMenus(menues) {
    if (menues && menues.length > 0) {
      let text = '';
      for (let i = 0; i < menues.length; i++) {
        text += menues[i].Name;
        if (i !== menues.length - 1) text += ',';
      }
      return text;
    }
  }

  function onClose() {
    clearFields();
    handleClose();
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialogBoxCls adminDialog"
      >
        <DialogTitle id="alert-dialog-title">
          Please create your profile signifier
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <input
              value={title}
              type="text"
              placeholder="Please enter profile signifier title"
              className="textField"
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleError(false);
              }}
            />
            {isTitleError && (
              <span style={{ color: 'red' }}>Please add title</span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <RadioGroup
              defaultValue={type}
              row
              aria-label="position"
              name="position"
              onChange={(e) => setType(e.target.value)}
            >
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    color="primary"
                    checked={type === '2' ? 'checked' : ''}
                  />
                }
                label="Textarea"
              />
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    color="primary"
                    checked={type === '1' ? 'checked' : ''}
                  />
                }
                label="Dropdown"
              />
            </RadioGroup>
          </DialogContentText>
          {type === '1' && (
            <DialogContentText
              id="alert-dialog-description"
              className="saveContainer dialogButtons"
            >
              <input
                type="text"
                value={dropMenus}
                placeholder="Please enter list item in comma separated"
                className="textField"
                onChange={(event) => setDropMenues(event.target.value)}
              />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className="dialogButtons">
          <Button
            onClick={validateSignifier}
            color="primary"
            autoFocus
            className="confirmBtn"
          >
            {btnText}
          </Button>
        </DialogActions>

        {signifierList && signifierList.length > 0 && (
          <div className="gridWrapper">
            <ul className="campusGrid gridHeading">
              <li>Signifier name </li>
              <li>Action</li>
            </ul>
            {signifierList.map((data, i) => renderRowItem(data, i))}
          </div>
        )}
      </Dialog>
    </div>
  );
}

ProfileSignifierDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  signifierList: PropTypes.array,
  onSignifierCreate: PropTypes.func,
  onSignifierUpdate: PropTypes.func,
  onDeleteSignifier: PropTypes.func
};
