import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import accountReducers from '../components/accounts/accountReducers';
import dashboardReducer from '../components/user/dashboard/dashboardReducer';
import applicantReducer from '../components/user/applicant/applicantReducer';
import studentReducer from '../components/user/students/studentReducer';
import SocietiesReducer from '../components/user/societies/SocietiesReducer';
import appSettingsReducer from '../components/user/appSettings/appSettingsReducer';

export default combineReducers({
  userAccount: accountReducers,
  toastr: toastrReducer,
  dashboard: dashboardReducer,
  students: studentReducer,
  applicants: applicantReducer,
  societies: SocietiesReducer,
  appSettings: appSettingsReducer
});
