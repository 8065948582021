import { Common } from "../../utils";
import { axiosBasic } from "../../api/axios";
import { config } from "../../api/endPoints";

const { Domains } = config.REST_API;

export const onLoadDomain = ({ universityId }) => (dispatch) => {
  return axiosBasic
    .get(`${Domains.GetUniversityDomains}${universityId}`)
    .catch((err) => {
      console.log(err);
    });
};

export const onCreateDomain = ({ domainName, universityId }, onSuccess) => (
  dispatch
) => {
  axiosBasic
    .post(Domains.CreateDomain, {
      Name: domainName,
      UniversityId: universityId,
    })
    .then((res) => {
      const { success, message } = res.data;
      if (success) {
        Common.showSuccessMessage(message);
      } else {
        Common.showErrorMessage(message);
      }
      onSuccess(success);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onUpdateDomain = (selectedDomain, onSuccess) => (dispatch) => {
  axiosBasic
    .put(Domains.UpdateDomain, selectedDomain)
    .then((res) => {
      const { success, message } = res.data;
      if (success) {
        Common.showSuccessMessage(message);
      } else {
        Common.showErrorMessage(message);
      }
      onSuccess(success);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const onDeleteDomain = (selectedDomain, onSuccess) => (dispatch) => {
  axiosBasic
    .delete(Domains.ControllerName, { data: selectedDomain })
    .then((res) => {
      const { success, message } = res.data;
      if (success) {
        Common.showSuccessMessage(message);
      } else {
        Common.showErrorMessage(message);
      }
      onSuccess(success);
    })
    .catch((err) => {
      console.log(err);
    });
};
