import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, TextField, Button, FormControlLabel } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as applicantActions from './applicantActions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getFormatedDate, getIsoFormatedDate, messages } from '../../../utils';

const { isRequired } = messages.validation;

// Validation schema
const validationSchema = yup.object().shape({
  Email: yup.string().required(`Email ${isRequired}`).email('Invalid email format'),
  VerificationExpiryDate: yup.string().required(`Verification Expiry Date ${isRequired}`),
  IsDeleted: yup.boolean()
});

const UpdateApplicant = ({
  state: { applicantList },
  applicantActions: { updateApplicant },
  onClose,
  applicantId
}) => {
  const [applicant, setApplicant] = useState({
    Id: 0,
    UniversityId: 0,
    Email: '',
    IsDeleted: false,
    VerificationExpiryDate: ''
  });

  // Hook Form for validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    const applicantData = applicantList.find((a) => a.Id === applicantId);
    const formatedApplicantData = {
      ...applicantData,
      VerificationExpiryDate: getFormatedDate(applicantData.VerificationExpiryDate)
    };

    setApplicant(formatedApplicantData);
    reset(formatedApplicantData);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setApplicant((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  }, []);

  const onSubmit = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSave();
    }
  }, [applicant]);

  const handleSave = async () => {
    const applicantWithoutFormating = {
      ...applicant,
      VerificationExpiryDate: getIsoFormatedDate(applicant.VerificationExpiryDate)
    };

    await updateApplicant(applicantWithoutFormating);
    onClose();
  };

  return (
    <div className="EditContainer">
      <div className="formContainer">
        <div className="closeBtn">
          <CloseIcon fontSize="large" onClick={onClose} />
        </div>
        <h2>Edit Applicant</h2>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {/* Email Field */}
          <TextField
            {...register('Email')}
            onChange={handleChange}
            value={applicant.Email}
            className="formTextField field-spacing"
            label="Email"
            fullWidth
            error={!!errors.Email}
            helperText={errors.Email?.message}
          />

          {/* Expiry Date Field */}
          <TextField
            {...register('VerificationExpiryDate')}
            onChange={handleChange}
            value={applicant.VerificationExpiryDate}
            className="formTextField field-spacing"
            label="Expiry Date"
            fullWidth
            error={!!errors.VerificationExpiryDate}
            helperText={errors.VerificationExpiryDate?.message}
          />

          {/* Deleted Checkbox */}
          <FormControlLabel
            {...register('IsDeleted')}
            checked={applicant.IsDeleted}
            onChange={handleChange}
            control={<Checkbox style={{ color: 'white' }} />}
            label="Deleted"
            className="formCheckbox field-spacing"
          />

          {/* Save Button */}
          <div className="divFormButton">
            <Button
              className="form-button-without-margin"
              variant="contained"
              type="button"
              onClick={onSubmit}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

UpdateApplicant.propTypes = {
  state: PropTypes.shape({
    universities: PropTypes.any
  }),
  applicantActions: PropTypes.shape({
    updateApplicant: PropTypes.func
  })
};

export default connect(
  (state) => ({
    state: state.applicants
  }),
  (dispatch) => ({
    applicantActions: bindActionCreators(applicantActions, dispatch)
  })
)(UpdateApplicant);
