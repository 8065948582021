import { actionTypes } from '../../../utils';
const { APPLICANT_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES, SET_APPLICANTS } = APPLICANT_ACTIONS;

const INITIAL_STATE = {
  universities: [],
  applicantList: []
};

export default (state = INITIAL_STATE, action = {}) => {
  if (action.type === SET_UNIVERSITIES)
    return {
      ...state,
      universities: [...action.payload]
    };
  else if (action.type === SET_APPLICANTS)
    return {
      ...state,
      applicantList: [...action.payload]
    };
  else {
    return state;
  }
};
