export const messages = {
  validation: {
    invalidEmail: 'Invalid email address',
    minimumPassword: 'Must be at least 6 characters',
    requiredField: 'Required field',
    isRequired: 'is Required'
  },
  Exception: {
    networkError: 'Network Error',
    sessionExpired: 'Session Expired'
  },
  Errors: {
    requestFailed: 'Request failed, Please try again.',
    noDataReturned: 'No data returned from the server.'
  }
};
