import { actionTypes, Common } from '../../../utils';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';

const { DASHBOARD_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES, SET_STUDENTS, CLEAR_HEADER_TITLE, SET_HEADER_TITLE } =
  DASHBOARD_ACTIONS;

const { Dashboard } = config.REST_API;

export const setRecord = (key, value) => ({
  type: key,
  payload: value
});

export const setHeaderTitle = (value) => ({
  type: SET_HEADER_TITLE,
  payload: value
});

export const clearHeaderTitle = () => ({
  type: CLEAR_HEADER_TITLE
});

export const onGetUniverities = () => (dispatch) => {
  axiosBasic
    .get(Dashboard.GetUniversityDetail)
    .then((response) => {
      const {
        success,
        data: { universities },
        message
      } = response.data;

      if (success) {
        dispatch(setRecord(SET_UNIVERSITIES, universities));
      } else {
        Common.showErrorMessage(message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export const onGetStudents = () => (dispatch) => {
  axiosBasic
    .get(Dashboard.GetUniversityStudents)
    .then((response) => {
      const { success, data, message } = response.data;
      if (success) {
        dispatch(setRecord(SET_STUDENTS, data));
      } else {
        Common.showErrorMessage(message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
