import { actionTypes, Common } from '../../../utils';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';

const { SOCIETY_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES } = SOCIETY_ACTIONS;

const { University, Socities } = config.REST_API;

export const setUniversities = (value) => ({
  type: SET_UNIVERSITIES,
  payload: value
});

export const onGetUniverities = () => (dispatch) => {
  axiosBasic
    .get(University.University)
    .then((response) => {
      const { success, data, message } = response.data;
      if (success) {
        dispatch(setUniversities(data));
      } else {
        Common.showErrorMessage(message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const onUploadSocieties = (formData) => () =>
  axiosBasic({
    url: Socities.UniSocieties,
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
    .then((response) => {
      const { success, message } = response.data;
      if (success) {
        Common.showSuccessMessage('Clubs & Societies added successfully!');
      } else {
        Common.showErrorMessage(message);
      }
      return {
        success: success
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        success: false
      };
    });
