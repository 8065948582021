import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import Routes from './router/routes';
import { AppStore } from './store/appStore';
import { configureAxiosDefaults, axiosResponse } from './api/axios';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

class App extends Component {
  render() {
    configureAxiosDefaults();
    axiosResponse();
    return (
      <Provider store={AppStore}>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="bounceIn"
          transitionOut="bounceOut"
          progressBar
        />
        <Routes />
      </Provider>
    );
  }
}

export default App;
