import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GridView } from '../../../common';
import { StudentHeader } from '../../../../utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableRow, TableCell } from '@mui/material';
import * as studentAction from '../studentActions';
import DetailDialogBox from '../../../common/dialogBox/detailDialogBox';
import {
  clearHeaderTitle,
  setHeaderTitle
} from '../../dashboard/dashboardActions';
import PropTypes from 'prop-types';

const StudentGridView = ({
  studentActions,
  students: { filterList, studentList, ActiveInActiveMarkerDate },
  setTitle,
  getActiveInactiveStatus,
  isActive,
  IsValidDate
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [userId, setUserId] = useState('');
  const [selected, setSelected] = useState(null);

  const location = useLocation();

  const getStudent = () => {
    const { uniData } = location.state;
    studentActions.onGetStudents({ uniName: uniData.UniversityName });
  };

  const getStudents = () => {
    const { uniData } = location.state;
    setTitle(uniData.UniversityName);
    studentActions.onGetStudents({ uniName: uniData.UniversityName });
  };

  const onRowItemClick = (props) => {
    if (
      !props.IsEmailConfirmed ||
      (props.IsEmailConfirmed && props.IsUniApproved)
    ) {
      setUserId(props.Id);
      setOpenDialog(true);
      setSelected(props);
    }
  };

  const onConfirm = () => {
    if (userId !== '') {
      studentActions.onConfirmUser(userId, closeDialogBox, getStudents);
    }
  };

  const closeDialogBox = () => {
    setOpenDialog(false);
    setUserId('');
  };
  IsValidDate();
  getActiveInactiveStatus();
  isActive();

  const list = filterList.map((item) => ({
    ...item,
    status: getActiveInactiveStatus(
      item.LastActiveDate,
      ActiveInActiveMarkerDate
    )
  }));
  const universityApproved = (item) => {
    if (!item.IsUniApproved) {
      return 'noneEmail';
    } else if (!item.IsEmailConfirmed) {
      return 'yellowEmail';
    } else return 'email';
  };

  const isDeletedUser = (item) => item.DeletionReasonType;
  const emptyLine = '________';

  return (
    <>
      {studentList?.length > 0 ? (
        <GridView
          dataList={list}
          headers={StudentHeader}
          onRowItemClick={(item) => onRowItemClick(item)}
          renderRowItem={(item, i) => (
            <TableRow
              className={isDeletedUser(item) ? 'Disabled cursorDefault' : ''}
              key={`rowItem-${i}`}
              onClick={() => !isDeletedUser(item) && onRowItemClick(item)}
            >
              <TableCell>
                {isDeletedUser(item) ? emptyLine : item.StudentName}
              </TableCell>
              <TableCell
                className={!isDeletedUser(item) && universityApproved(item)}
              >
                {isDeletedUser(item) || item.IsUniApproved
                  ? item.Email
                  : 'none'}
              </TableCell>
              <TableCell
                className={!isDeletedUser(item) && universityApproved(item)}
              >
                {item.OtherEmail}
              </TableCell>
              <TableCell>
                {isDeletedUser(item) ? emptyLine : item.status}
              </TableCell>
              <TableCell>
                {item.LastActiveDate
                  ? new Date(item.LastActiveDate).toLocaleDateString()
                  : emptyLine}
              </TableCell>
            </TableRow>
          )}
        />
      ) : (
        <div className="ErrorPage">
          <div>
            <div>Oops, there are no students at this University .</div>
            <a href="/">Take me home</a>
          </div>
        </div>
      )}
      {openDialog && (
        <DetailDialogBox
          onUpdate={getStudent}
          details={selected}
          openDialog={openDialog}
          onCancel={() => closeDialogBox()}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

StudentGridView.propTypes = {
  studentActions: PropTypes.shape({
    onGetStudents: PropTypes.func,
    onConfirmUser: PropTypes.func
  }),
  students: PropTypes.shape({
    filterList: PropTypes.func,
    studentList: PropTypes.array,
    ActiveInActiveMarkerDate: PropTypes.any
  }),
  setTitle: PropTypes.func,
  getActiveInactiveStatus: PropTypes.func,
  isActive: PropTypes.func,
  IsValidDate: PropTypes.func,
  IsEmailConfirmed: PropTypes.bool,
  IsUniApproved: PropTypes.bool,
  Id: PropTypes.string
};

export default connect(
  (state) => ({
    students: state.students
  }),
  (dispatch) => ({
    studentActions: bindActionCreators(studentAction, dispatch),
    clearTitle: () => {
      dispatch(clearHeaderTitle());
    },
    setTitle: (prop) => {
      dispatch(setHeaderTitle(prop));
    }
  })
)(StudentGridView);
