import { actionTypes } from '../../../utils';
const { STUDENT_ACTIONS } = actionTypes;
const { SET_RECORD, CLEAR_STUDENTS, SET_FILTER_STUDENTS } = STUDENT_ACTIONS;
const INITIAL_STATE = {
  university: {
    id: '',
    name: '',
    loading: true
  },
  studentList: [],
  filterList: [],
  ActiveInActiveMarkerDate: null
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_RECORD:
      const { students, uniData, ActiveInActiveMarkerDate } = action.payload;
      return {
        ...state,
        studentList: students,
        university: uniData,
        ActiveInActiveMarkerDate: ActiveInActiveMarkerDate
      };
    case SET_FILTER_STUDENTS:
      return {
        ...state,
        filterList: action.payload
      };

    case CLEAR_STUDENTS: {
      return {
        ...state,
        studentList: []
      };
    }
    default:
      return state;
  }
};
