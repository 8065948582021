import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../../resources/images/umii-logo.png';
import * as AccontAction from '../accountActions';
import LoginForm from './loginForm';
import PropTypes from 'prop-types';

const Login = ({ accountActions }) => {
  const navigate = useNavigate();
  const handleSubmit = (values) => {
    accountActions.onLoginUser({
      email: values.email,
      password: values.password,
      navigation: navigate
    });
  };

  return (
    <div className="loginContainer">
      <div className="outlineLeft">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="outlineRight">
        <LoginForm handleSubmitBtn={(values) => handleSubmit(values)} />
      </div>
    </div>
  );
};

Login.propTypes = {
  accountActions: PropTypes.shape({
    onLoginUser: PropTypes.func
  })
};

const mapStateToProps = ({ userAccount }) => {
  const { initiated } = userAccount;
  return {
    initiated
  };
};

const mapDispatchToProps = (dispatch) => ({
  accountActions: bindActionCreators(AccontAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
